<template>
  <div class="roi-area">
    <div class="roi-header">
      <div class="label">
        <img src="@/assets/icons/WarningArea.svg" alt="">
        {{ $t('ai_warning_area')/*警戒區*/ }}
        <TooltipElement :content="$t('ai_tooltip_warning_area')" alwaysShow placement="top">
          <img class="tip-icon" src="@/assets/icons/info.svg" alt="">
        </TooltipElement>
      </div>
      <div class="roi-mode" @click="toggleEditRoi()">
        <div v-if="isEditRoi" class="roi-lock">
          <img src="@/assets/icons/roi-lock.svg" alt="">
          <span>{{ $t('ai_roi_lock')/*鎖定辨識範圍*/ }}</span>
          <div class="line"></div>
          <TooltipElement :content="$t('ai_tooltip_roi_reset')" alwaysShow placement="top">
            <div class="roi-refresh" @click.stop="onRoiReset">
              <img src="@/assets/icons/roi-refresh.svg" alt="">
            </div>
          </TooltipElement>
        </div>
        <div v-else class="roi-edit">
          <img src="@/assets/icons/roi-edit.svg" alt=""> 
          <span>{{ $t('ai_roi_set')/*調整辨識範圍*/ }}</span>
        </div>
      </div>
    </div>
    
    <div class="btns">
      <div v-for="no in [1, 2, 3]" :key="no" class="btn" @click="setArea(no)">
        
        <div class="set-area" v-if="no === setAreaNo || orData[`area${no}`].length > 0">
          <div class="area-close" @click.stop="removeArea(no)">
            <img src="@/assets/icons/close.svg" alt="">
          </div>
          {{ $t(`ai_area${no}`)/*區域一*/ }}
        </div>
        <img v-else src="@/assets/icons/plus.svg" alt="">
      </div>
      <!-- <div class="btn" @click="setArea(2)">
        <div class="set-area" v-if="setAreaNo === 2 || aiBoxTask.config.area2.length > 0">
          <div class="area-close" @click.stop="removeArea(2)">
            <img src="@/assets/icons/close.svg" alt="">
          </div>
          {{ $t('ai_area2')/*區域二*/ }}
        </div>
        <img v-else src="@/assets/icons/plus.svg" alt="">
      </div>
      <div class="btn" @click="setArea(3)">
        <div class="set-area" v-if="setAreaNo === 3 || aiBoxTask.config.area3.length > 0">
          <div class="area-close" @click.stop="removeArea(3)">
            <img src="@/assets/icons/close.svg" alt="">
          </div>
          {{ $t('ai_area3')/*區域三*/ }}
        </div>
        <img v-else src="@/assets/icons/plus.svg" alt="">
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'SetRoiArea',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('aibox', ['aiRunOn', 'isEditRoi']),
    ...mapGetters('recgOr', ['currentSetting', 'orChannelIndex']),
    ...mapGetters('aibox', ['aiBoxTask']),
    // 根據本機辨識或雲端辨識，取得對應的警戒區設定資料
    orData() {
      if (this.aiRunOn === 'device') {
        return this.currentSetting.setting.channel[this.orChannelIndex].or
      } else {
        return this.aiBoxTask.config
      }
    },
    module() {
      return this.aiRunOn === 'device' ? 'recgOr' : 'aibox'
    },
    setAreaNo() {
      return this.$store.state[this.module].setAreaNo
    }
  },
  methods: {
    ...mapMutations('aibox', ['updateIsEditRoi']),
    ...mapMutations('recgOr', ['updateSetAreaNo', 'updatePolygonMode']),
    setArea(no) {
      // 警戒區若以設定點，需要先清空，才能重新設定
      if (this.orData[`area${no}`].length === 0) {
        this.$store.commit(`${this.module}/updatePolygonMode`, true)
        this.$store.commit(`${this.module}/updateSetAreaNo`, no)
      }
    },
    removeArea(no) {
      this.orData[`area${no}`] = []
      this.$store.commit(`${this.module}/updateSetAreaNo`, -1)
      // this.updatePolygonMode(false)
    },
    toggleEditRoi() {
      this.updateIsEditRoi(!this.isEditRoi)
    },
    onRoiReset() {
      this.$store.commit(`${this.module}/updateIsResetRoi`, true)
    }
  }
}
</script>

<style lang="scss" scoped>
.roi-area {
  width: 100%;
  height: 100%;
}
.roi-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 6px;
  height: 36px;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #ffffff;
}

.label {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #FFE99F;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;

  .tip-icon {
    width: 20px;
    height: 20px;
    @include filter_FFF_50;
    cursor: pointer;
  }
}

.roi-edit, .roi-lock {
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
  span {
    padding-left: 6px;
  }

  .line {
    width: 1px;
    height: 36px;
    background: #ffffff20;
    margin: 0 4px;
  }
}

.roi-lock span {
  // padding-left: 4px;
  // padding-right: 12px;
  color: #FFC600;
}

.roi-refresh {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  &:hover {
    background: #ffffff20;
  }
  // padding-left: 12px;
  // border-left: 1px solid #ffffff;
  img {
    width: 20px;
    height: 20px;
    @include filter_FFF;
  }
}

.btns {
  display: flex;
  // flex-wrap: wrap;
  column-gap: 8px;
}

.btn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-radius: 9px;
  font-size: 16px;
  color: #ffffff;
  white-space: nowrap;
  cursor: pointer;
}

.btn img {
  width: 20px;
  height: 20px;
}

.btn:nth-child(1) {
  background: #F94144;
}

.btn:nth-child(2) {
  background: #F99D41;
}

.btn:nth-child(3) {
  background: #4361EE;
}
.btn:nth-child(1) .area-close {
  background: #fa7678;
}

.btn:nth-child(2) .area-close {
  background: #feb66e;
}

.btn:nth-child(3) .area-close {
  background: #7790ff;
}

.set-area {
  display: flex;
  align-items: center;
}

.area-close {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f56769;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 12px;
}

.area-close img {
  width: 14px;
  height: 14px;
}
</style>